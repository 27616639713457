<script setup lang="ts">
import NewsWaitingListItem from "./NewsWaitingListItem.vue"
import PanelList from "@/ContextTab/components/List/PanelList.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import PsTag from "@/ContextTab/components/UI/PsTag.vue"
import AuthorsTree from "@/ContextTab/modules/news/authors/AuthorsTree.vue"
import CodesPopover from "@/ContextTab/modules/news/codes/components/CodesPopover.vue"
import { ref, computed, reactive, watch } from "vue"
import userAuthInfo from "@/ContextTab/modules/auth/composables/useAuthInfo"
import useTaskPanelList from "@/ContextTab/modules/news/tasks-panel/composables/useTaskPanelList"
import useProfile from "@/ContextTab/modules/user/composables/useProfile"
import type { DateMode } from "@/ContextTab/components/Date/types"

withDefaults(
  defineProps<{
    dateMode: DateMode
    isLegacyRoute: boolean
    loading?: boolean
  }>(),
  {
    loading: false,
  }
)

const emit = defineEmits<{
  (e: "open", id: number): void
  (e: "pick", id: number): void
  (e: "dateModeChanged", value: DateMode): void
}>()

const showFilters = ref(false)

const filterState: { [key: string]: { id: string; state: string } } = reactive(
  {}
)
const { userId } = userAuthInfo()

const {
  items,
  reload,
  selectedId,
  selectItem,
  onReachedEnd,
  hasMoreItems,
  isFetching,
  errors,
} = useTaskPanelList("waiting")

const { name, asoiName, userGroups, profile, updateFilters } = useProfile({})

const userItem = computed(() => {
  if (!userId.value) {
    return {}
  }
  return {
    [userId.value]: {
      id: userId.value,
      name: name.value,
      first_name: name.value,
      asoi_name: asoiName.value,
      children: [],
      isGroup: false,
      track: [0],
    },
  }
})

const groupItems = computed(() => {
  return userGroups.value.reduce((acc: any, group: any) => {
    acc[group.id] = {
      uid: globalThis.crypto.randomUUID(),
      isGroup: true,
      children: [],
      track: [0],
      ...group,
    }
    return acc
  }, {})
})

const filterGroups = computed(() => {
  return {
    ...userItem.value,
    ...groupItems.value,
  }
})

const waitingListFilters = computed(
  () => profile.value?.filters?.["news_waiting"]
)

const appliedFilters = computed(() =>
  [
    ...(waitingListFilters.value?.groups?.ids ?? []).map(
      (id: string) => filterGroups.value[id]
    ),
    ...(waitingListFilters.value?.users?.ids ?? []).map(
      (id: string) => filterGroups.value[id]
    ),
  ]
)

const setAppliedFilters = () => {
  Object.keys(filterState).forEach(id => {
    if (!appliedFilters.value.find(filter => filter.id === id)) {
      delete filterState[id]
    }
  })
  appliedFilters.value.forEach(({ id }) => {
    filterState[id] = { id, state: "included" }
  })
}

watch(appliedFilters, setAppliedFilters, { immediate: true })

const changeItemState = (item: any, state: string) => {
  if (state === "included") {
    includeItem(item.id)
  } else {
    removeItem(item.id)
  }
}

const includeItem = (id: string) => {
  filterState[id] = { id, state: "included" }
}

const removeItem = (id: string) => {
  delete filterState[id]
}

const applyFilters = () => {
  updateFilters("news_waiting", {
    groups: {
      ids: Object.keys(filterState)
        .filter(id => filterGroups.value[id]?.isGroup === true)
        .map(id => Number(id)),
    },
    users: {
      ids: Object.keys(filterState)
        .filter(id => filterGroups.value[id]?.isGroup === false)
        .map(id => Number(id)),
    },
  })
  reload()
  showFilters.value = false
}

const removeFilter = (id: string) => {
  removeItem(id)
  applyFilters()
}

const cancelFilters = () => {
  showFilters.value = false
  setAppliedFilters()
}
</script>

<template>
  <PanelList
    :items="items"
    :selectedItemId="selectedId"
    :hasMoreItems="hasMoreItems"
    :errors="errors"
    :isFetching="isFetching"
    :keyboardEnabled="false"
    class="panel-list__waiting"
    dateDelimeterColor="#f3e4ca"
    @select="selectItem"
    @reachedEnd="onReachedEnd"
  >
    <template #before>
      <div class="filter-popover__filters">
        <CodesPopover
          v-if="userGroups?.length"
          :open="showFilters"
          @cancel="cancelFilters"
          @submit="applyFilters"
        >
          <div class="filter-popover__filters-wrapper">
            <PsButton
              class="filter-popover__button"
              size="sm"
              @click="showFilters = true"
            >
              <template v-if="appliedFilters.length" #prefix>
                <PsIcon
                  class="filter-popover__button-icon"
                  name="triangle-right"
                  :size="15"
                />
              </template>
              <span v-if="!appliedFilters.length">Все ящики</span>
            </PsButton>

            <template v-if="appliedFilters.length">
              <PsTag
                v-for="filter in appliedFilters"
                :key="filter.id"
                class="filter-popover__filter-tag"
                size="sm"
                :closable="true"
                @close="() => removeFilter(filter.id)"
              >
                {{
                  filter.asoi_name ||
                  filter.code ||
                  filter.name ||
                  filter.first_name
                }}
              </PsTag>
            </template>
          </div>
          <template #main>
            <AuthorsTree
              :items="filterGroups"
              :filterState="filterState"
              placeholder="Поиск по ящикам"
              @change="changeItemState"
            />
          </template>
        </CodesPopover>
      </div>
    </template>
    <template #default="{ item, isSelected }">
      <NewsWaitingListItem
        :item="item"
        :isSelected="isSelected"
        :dateMode="dateMode"
        :isLegacyRoute="isLegacyRoute"
        :loading="loading"
        @dateModeChanged="$emit('dateModeChanged', $event)"
        @open="emit('open', $event)"
        @pick="emit('pick', $event)"
      />
    </template>
  </PanelList>
</template>

<style lang="postcss" scoped>
.filter-popover__filters {
  padding: calc(0.5rem - 2px) 1rem;
  background-color: #f3e4ca;
}

.filter-popover__filters-wrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  & .filter-popover__button {
    --button--background: rgb(189, 152, 92);
    --button--color: var(--color-white);
    --button--hover--background: rgba(189, 152, 92, 0.8);
    --button--hover--border-color: rgb(189, 152, 92);

    &:not(:disabled, .-disabled):hover {
      --button--color: var(--color-white);
    }
  }

  & .filter-popover__filter-tag {
    --badge--border-color: rgb(189, 152, 92);
    --badge--hover--close-background: rgba(189, 152, 92, 0.4);
  }
}

.filter-popover__applied-filters {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.filter-popover__button-icon {
  transform: rotate(90deg);
}

.panel-list__waiting {
  display: flex;
  flex-direction: column;
}
</style>
