<template>
  <div class="splash-screen">
    <img
      src="@/assets/images/logo.svg"
      alt="Интерфакс"
    >
  </div>
</template>

<style scoped lang="postcss">
.splash-screen {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  & img {
    height: 30%;
    max-height: 192px;
    border-radius: 10px;
  }
}
</style>
