import type { SizeBreakpointMark, SizeBreakpoints } from '@/ContextTab/composables/types'
import { useElementSize } from "@vueuse/core"
import { computed, type Ref } from 'vue'
import { SizeBreakpointMarksOrdered } from '@/ContextTab/composables/types'

export function useContainerBreakpoints(
  element: Ref<HTMLElement | null>,
  breakpoints: Ref<SizeBreakpoints | undefined>,
) {

  const { width } = useElementSize(element)

  const isInitialized = computed(() =>
    element
    && element.value
    && breakpoints
    && breakpoints.value
  )

  const passedBreakpointMarks = computed(() =>
    isInitialized.value
      ? SizeBreakpointMarksOrdered
        .filter((mark) => typeof breakpoints?.value?.[mark] === 'number')
      : []
  )

  const currentBreakpointMark = computed<SizeBreakpointMark>(() => {
    if (isInitialized.value) {
      for (let i = passedBreakpointMarks.value.length - 1; i >= 0; i--) {
        const currMark = passedBreakpointMarks.value[i]
        // in the next line breakpoints[mark] in fact must exist
        if (
          typeof breakpoints?.value?.[currMark] === 'number'
          && breakpoints.value[currMark] <= width.value
        ) {
          return currMark
        }
      }
      console.warn(
        'useContainerBreakpoints: no matching breakpoint for width!',
        width.value,
        JSON.parse(JSON.stringify(breakpoints?.value))
      )
    }
    return SizeBreakpointMarksOrdered[0]
  })

  // all marks <= current to iterate by them
  const marksLessOrEqual = computed(() => {
    const currIndex = SizeBreakpointMarksOrdered.indexOf(currentBreakpointMark.value)
    return SizeBreakpointMarksOrdered.slice(0, currIndex + 1)
  })

  function isSmallerOrEqual(size: SizeBreakpointMark): boolean {
    return (
      SizeBreakpointMarksOrdered.indexOf(currentBreakpointMark.value)
      <= SizeBreakpointMarksOrdered.indexOf(size)
    )
  }

  return {
    currentBreakpointMark,
    marksLessOrEqual,
    isSmallerOrEqual,
  }
}
