<script setup lang="ts">
import TasksPanelListItem from "../components/TasksPanelListItem.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import NewsAssignBadge from "@/ContextTab/modules/news/tasks-panel/components/NewsAssignBadge.vue"
import { toRef } from "vue"
import { DateMode } from "@/ContextTab/components/Date/types"
import useNewsItem from "@/ContextTab/modules/news/composables/useNewsItem"
import useNewsItemVersion from "@/ContextTab/modules/news/composables/useNewsItemVersion"

const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    dateMode?: DateMode
    isLegacyRoute: boolean
  }>(),
  {
    isSelected: false,
    dateMode: DateMode.Regular,
  }
)
const emit = defineEmits<{
  (e: "open", id: number): void
  (e: "pick", id: number): void
}>()

const { newsItemIsReadyToEdit } = useNewsItem(toRef(() => props.item.id))

const { getVersionStatusDescription } = useNewsItemVersion(
  toRef(() => props.item.id)
)

const open = (handleDblClick = false) => {
  emit("open", props.item.id)

  if (handleDblClick && newsItemIsReadyToEdit.value) {
    emit("pick", props.item.id)
  }
}
</script>

<template>
  <TasksPanelListItem
    class="my-list-item"
    :item="item"
    :isSelected="isSelected"
    :dateMode="dateMode"
    @dblclick="() => open(true)"
  >
    <template #before-text>
      <NewsAssignBadge :itemId="item.id" />
    </template>
    <template #expansion-extra>
      <div class="my-list-item__status">{{ getVersionStatusDescription }}</div>
    </template>
    <template #footer>
      <div v-if="isSelected" class="my-list-item__footer">
        <PsTooltip
          :content="
            isLegacyRoute
              ? 'Открыть в отдельной панели'
              : 'Открыть в новой вкладке'
          "
          placement="top"
        >
          <PsButton
            circle
            :border="false"
            class="action-button"
            @click="() => open(false)"
          >
            <PsIcon name="button-icon-show-more" :size="24" />
          </PsButton>
        </PsTooltip>
      </div>
    </template>
  </TasksPanelListItem>
</template>

<style lang="postcss" scoped>
.my-list-item__footer .action-button:not(:disabled, .-disabled):hover {
  --button--color: #bd985c;
}
.my-list-item__status {
  color: var(--color-gray-300);
  font-size: 12px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
