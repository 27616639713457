<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PsOneLineInput from "@/ContextTab/components/UI/Form/PsOneLineInput.vue"
import PsPopover from "@/ContextTab/components/UI/Popover/PsPopover.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { ref } from "vue"
import { useStoreAsync } from "@/ContextTab/useStore"
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'
import deepToRaw from "@/utils/deepToRaw"
import type { PublishedPresetsStore } from "@/ContextApp/stores/news/published/presets"

const props = defineProps<{
  disabled: boolean
  filters: any
}>()

const emit = defineEmits(["click", "apply"])
const presetStore = await useStoreAsync<PublishedPresetsStore>("publishedPresets")

const name = ref("")
const showInput = ref(false)

const errors = ref(null)

const { isScreenExtraSmall } = useScreenBreakpoints()
const busy = ref(false)

const onClose = () => {
  busy.value = false
  showInput.value = false
  name.value = ""
}

async function create(name: string) {
  busy.value = true
  const createResult = await presetStore.createPreset(
    name,
    deepToRaw(props.filters)
  )
  if (createResult.payload.errors) {
    errors.value = createResult.payload.errors
    busy.value = false
  } else {
    onClose()
    emit("apply", createResult.payload.id)
  }
  return createResult
}
</script>

<template>
  <PsPopover
    trigger="manual"
    :open="showInput"
    :themeOverrides="{ padding: 0 }"
    class="new-preset-popover"
    placement="bottom"
  >
    <div>
      <PsFilterButton
        size="sm"
        :color="isScreenExtraSmall ? 'default' : null"
        :tooltip="
          disabled
            ? 'Измените фильтры, чтобы создать новый пресет'
            : 'Создать новый пресет из текущих фильтров'
        "
        tooltipPlacement="bottom"
        showTooltip="always"
        :contentStyle="{ gap: '0.25rem' }"
        :disabled="busy || disabled"
        @click="showInput = !showInput"
      >
        <template #prefix>
          <PbIcon
            name="plus"
            size="14"
          />
        </template>
        <template #xs>Создать пресет</template>
        <template #sm></template>
        <template #xl>Создать пресет</template>
      </PsFilterButton>
    </div>
    <template #body>
      <PsOneLineInput
        :active="name?.trim()?.length > 0"
        :busy="busy"
        :errors="errors"
        name="name"
        placeholder="Название пресета"
        :onApply="create"
        @cancel="onClose"
      />
    </template>
  </PsPopover>
</template>

<style lang="postcss">
.new-preset-popover {
  --popover--padding: 0;
}
</style>
