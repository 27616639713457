<script setup lang="ts">
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'

defineProps<{
  name: string
  icon: string
  label: string
  color?: string
  active?: boolean
}>()

const emit = defineEmits<{
  (e: "click"): void,
}>()

const { isScreenSmall } = useScreenBreakpoints()
</script>

<template>
  <div class="ps-main-nav-button__wrapper">
    <PsTooltip
      :content="label"
      placement="right"
      :disabled="isScreenSmall"
    >
      <div
        class="ps-main-nav-button"
        :class="{ active, sm: isScreenSmall }"
        @click="emit('click')"
      >
        <PbIcon :name="icon" />
      </div>
    </PsTooltip>
  </div>
</template>

<style scoped lang="postcss">

/* failed to tell popover to take whole space in another way */
.ps-main-nav-button__wrapper {
  width: 100%;
  height: 100%;

  &:deep(.pskit__popover) {
    width: 100%;
    height: 100%;
  }
}

.ps-main-nav-button {
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--color-gray-200);
  transition: 0.3s ease color;
  width: 100%;
  height: 3rem;

  &.active,
  &:hover {
    color: v-bind(color);
  }

  &.active {
    cursor: default;
    background: radial-gradient(
      35% 50% at 100% 50%,
      v-bind(color) 0%,
      var(--color-dark-shade-150) 100%
    );
  }

  &.sm {
    height: 100%;
    width: 3rem;
  }

  &.sm.active {
    background: radial-gradient(
      55% 50% at 50% 105%,
      v-bind(color) 0%,
      var(--color-dark-shade-150) 100%
    );
  }

  & + .ps-main-nav-button:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-gray-700);
  }

  &.sm + .ps-main-nav-button:before {
    width: 1px;
    height: 100%;
  }
}
</style>
