<script setup lang="ts">
import PsButton from "./PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import { h, inject, type VNode } from "vue"
import type { Color } from "@/ContextTab/components/UI/types"
import { ContainerBreakpointsKey } from "@/injectionKeys"
import { type useContainerBreakpoints } from "@/ContextTab/composables/useContainerBreakpoints"
import type { SizeBreakpointMark } from "@/ContextTab/composables/types"


const props = withDefaults(
  defineProps<{
    size: SizeBreakpointMark
    color?: Color
    tooltip?: string
    showTooltip?: SizeBreakpointMark[] | "always"
    tooltipPlacement?: string
    buttonComp?: any
    disabled?: boolean
  }>(),
  {
    tooltip: "",
    showTooltip: () => ["xs", "sm"],
    tooltipPlacement: "bottom",
    buttonComp: PsButton,
    disabled: false,
    color: undefined,
  }
)

const emit = defineEmits(["click"])

const slots = defineSlots<
  Partial<Record<
    SizeBreakpointMark
      | "default"
      | "prefix"
      | "suffix",
    () => VNode[]
  >>
>()

const containerBreakpoints = inject<ReturnType<typeof useContainerBreakpoints> | undefined>(
  ContainerBreakpointsKey
)

if (!containerBreakpoints) {
  console.warn("PsResponsiveButton: no breakpoints provided!")
}

const renderContent = () => {
  if (containerBreakpoints) {
    for (let i = containerBreakpoints.marksLessOrEqual.value.length; i >= 0; i--) {
      const mark = containerBreakpoints.marksLessOrEqual.value[i]
      const slot = slots[mark]
      if (slot) {
        return slot()
      }
    }
  }

  return slots.default ? slots.default() : null
}

const renderSlot = (name: string) => {
  return slots?.[name]?.()
}

const render = () =>
  h(
    PsTooltip,
    {
      content: props.tooltip,
      disabled: !(
        props.showTooltip === "always"
        || (
          containerBreakpoints?.currentBreakpointMark.value
          && props.showTooltip.includes(containerBreakpoints?.currentBreakpointMark.value)
        )
      ),
      placement: props.tooltipPlacement,
    },
    {
      default: () =>
        h(
          props.buttonComp,
          {
            ...props,
            onClick: (event) => emit("click", event),
          },
          {
            default: () => renderContent() ?? null,
            prefix: () => renderSlot("prefix"),
            suffix: () => renderSlot("suffix"),
          }
        ),
    }
  )
</script>

<template>
  <render />
</template>
