<script lang="ts" setup>
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { debounce as $debounce } from "lodash-es"
import { computed, ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { RefsStore } from "@/ContextApp/stores/references"

const props = withDefaults(
  defineProps<{
    typeId: number
    disabled?: boolean
    active?: boolean
    showLabel?: boolean
    iconSize?: number
    color?: string
  }>(),
  {
    disabled: false,
    active: false,
    showLabel: false,
    iconSize: 20,
    color: undefined,
  }
)

const emit = defineEmits<{
  (event: "click"): void
  (event: "dblclick"): void
}>()

const refsStore = useStore<RefsStore>("references", {
  shared: true,
})

const delayedClick = ref<null | (() => void)>(null)
const clickedOnce = ref(false)

const flashType = computed(() => refsStore.data?.newsTypesByName?.["FLASH"]?.id)
const expressType = computed(
  () => refsStore.data?.newsTypesByName?.["EXPRESS"]?.id
)

const iconName = computed(
  () =>
    "newsType/newstype-" +
      refsStore.data?.newsTypes?.[props.typeId]?.name.toLowerCase() || ""
)

const className = computed(() => {
  if (props.color) {
    return ""
  }
  if (props.typeId === flashType.value || props.typeId === expressType.value) {
    return "_text-color:primary-shade-150"
  } else {
    return "_text-color:inherit"
  }
})

const label = computed(
  () => refsStore.data?.newsTypes?.[props.typeId]?.description || ""
)

function clickHandler() {
  if (!delayedClick.value) {
    delayedClick.value = $debounce(doClick, 2000)
  }

  if (clickedOnce.value) {
    delayedClick.value.cancel()
    clickedOnce.value = false
  } else {
    delayedClick.value()
    clickedOnce.value = true
  }
}

function doClick() {
  emit("click")
  clickedOnce.value = false
}

function doubleClickHandler() {
  emit("dblclick")
}
</script>

<template>
  <PsButton
    :disabled="disabled"
    :padding="false"
    :border="false"
    :class="className"
    @click="clickHandler"
    @dblclick.stop.prevent="doubleClickHandler"
  >
    <slot name="icon">
      <PsIcon
        v-if="iconName"
        :name="iconName"
        :color="color"
        :size="iconSize"
      />
    </slot>
    <template v-if="showLabel">
      {{ label }}
    </template>
  </PsButton>
</template>

<style lang="postcss" scoped>
.ps-button {
  &.button.-disabled,
  &.button:disabled {
    cursor: default;
  }
}
</style>
