import messageHub from "@/ContextTab/services/messageHubClient"
import { messageTypes } from "@/lib/messageHub/messages"

export default function () {
  const republish = (newsId: string | number) => {
    messageHub.sendMessage(
      {
        typeId: messageTypes.byName(messageTypes.names.service).id,
        signal: "legacyAction",
      },
      {
        contentType: "news",
        action: "republish",
        id: newsId,
      }
    )
  }

  const extend = (newsId: string | number) => {
    messageHub.sendMessage(
      {
        typeId: messageTypes.byName(messageTypes.names.service).id,
        signal: "legacyAction",
      },
      {
        contentType: "news",
        action: "extend",
        id: newsId,
      }
    )
  }

  const openNewsItem = (newsId: string | number) => {
    messageHub.sendMessage(
      {
        typeId: messageTypes.byName(messageTypes.names.service).id,
        signal: "legacyAction",
      },
      {
        contentType: "news",
        action: "openNewsItem",
        id: newsId,
      }
    )
  }

  const revoke = (newsId: string | number) => {
    messageHub.sendMessage(
      {
        typeId: messageTypes.byName(messageTypes.names.service).id,
        signal: "legacyAction",
      },
      {
        contentType: "news",
        action: "revoke",
        id: newsId,
      }
    )
  }

  const openCanvas = () => {
    messageHub.sendMessage(
      {
        typeId: messageTypes.byName(messageTypes.names.service).id,
        signal: "legacyAction",
      },
      {
        contentType: "news",
        action: "openCanvas"
      }
    )
  }

  return {
    republish,
    extend,
    openNewsItem,
    revoke,
    openCanvas
  }
}
