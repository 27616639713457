import { ref } from "vue"
import { VERSION } from '@/config'

export enum AppInitState {
  initial = "initial",
  started = "started",
  ready = "ready",
}

function define() {
  const state = ref(AppInitState.initial)
  const workerVersion = ref(VERSION)

  function setState(newState: AppInitState) {
    state.value = newState
  }

  return {
    state,
    setState,
    workerVersion,
  }
}

export type InitStore = ReturnType<typeof define>

export default {
  define,
}
