// ок что импорт не найден:
// https://vite-pwa-org.netlify.app/guide/auto-update.html#importing-virtual-modules
// eslint-disable-next-line import/no-unresolved
import { registerSW } from "virtual:pwa-register"
import { ref, type Ref } from "vue"

const CHECK_UPDATES_INTERVAL = 15 * 60 * 1000 // 15min

// код основан на vite-plugin-pwa
// https://vite-pwa-org.netlify.app/frameworks/vue.html
// https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html

// может быть переписан/упрошён:
// https://vite-pwa-org.netlify.app/frameworks/vue.html

class AppUpdate {
  private swRegistration: ReturnType<typeof registerSW>
  hasUpdate: Ref<boolean> = ref(false)

  constructor() {
    this.swRegistration = registerSW({
      onNeedRefresh: () => {
        this.hasUpdate.value = true
      },
      onRegisteredSW: (swScriptUrl: string, registration?: ServiceWorkerRegistration) => {
        if (registration) {
          // начинаем проверять обновления
          setInterval(async () => {
            if (
              !registration
              || registration.installing || !navigator
              || ('connection' in navigator) && !navigator.onLine
            ) {
              return
            }

            const resp = await fetch(swScriptUrl, {
              cache: 'no-store',
              headers: {
                'cache': 'no-store',
                'cache-control': 'no-cache',
              },
            })

            if (resp?.status === 200) {
              await registration.update()
            }
          }, CHECK_UPDATES_INTERVAL)
        }
      }
    })
  }

  async update() {
    await this.swRegistration()
  }
}

export default new AppUpdate()
