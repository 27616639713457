<script setup lang="ts">
import { ICheckbox } from "@inkline/inkline"
import { debounce as $debounce } from "lodash-es"
import { computed, ref } from "vue"
import type { SizeBreakpointMark } from "@/ContextTab/composables/types"

type CheckboxValue = string | number | boolean

interface Props {
  checked: CheckboxValue
  label?: string
  size?: SizeBreakpointMark
  disabled?: boolean
  checkedValue?: CheckboxValue
  uncheckedValue?: CheckboxValue
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  size: "md",
  disabled: false,
  checkedValue: true,
  uncheckedValue: false,
})

const emit = defineEmits<{
  (e: "update:checked", value: CheckboxValue): void,
}>()

const delayedClick = ref<null | ReturnType<typeof $debounce>>(null)
const clicked = ref(false)

const curValue = computed({
  get() {
    if (props.checkedValue && props.checked === props.checkedValue) {
      return true
    } else if (props.uncheckedValue && props.checked === props.uncheckedValue) {
      return false
    }
    return props.checked
  },
  set(val) {
    let newVal = val
    if (props.checkedValue && val) {
      newVal = props.checkedValue
    } else if (props.uncheckedValue && !val) {
      newVal = props.uncheckedValue
    }
    emit("update:checked", newVal)
  },
})

const clickHandler = (event: MouseEvent) => {
  // В компоненте ICheckbox есть баг, из-за которого событие click всплывает два раза.
  // Это трюк, для того, чтобы этого избежать
  if (!delayedClick.value) {
    delayedClick.value = $debounce(() => {
      clicked.value = false
    }, 200)
  }

  if (clicked.value) {
    event.stopPropagation()
    delayedClick.value.cancel()
    clicked.value = false
  } else {
    delayedClick.value()
    clicked.value = true
  }
}
</script>

<template>
  <ICheckbox
    v-model="curValue"
    class="ps-checkbox"
    :label="label"
    :size="size"
    :disabled="disabled"
    @click="clickHandler"
  >
    <slot />
  </ICheckbox>
</template>

<style scoped lang="postcss">
.ps-checkbox {
  white-space: nowrap;
}
</style>

<style lang="postcss">
.checkbox-label {
  gap: 8px;
}
</style>
