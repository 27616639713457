<script setup lang="ts">
import DateWidget from "@/ContextTab/components/Date/DateWidget.vue"
import EmbargoBadge from "@/ContextTab/components/Date/EmbargoBadge.vue"
import PsNewsTypeButton from "@/ContextTab/components/UI/Button/PsNewsTypeButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { computed, useSlots, toRef } from "vue"
import { DateMode } from "@/ContextTab/components/Date/types"
import useNewsItem from "@/ContextTab/modules/news/composables/useNewsItem"
import useNewsItemVersion from "@/ContextTab/modules/news/composables/useNewsItemVersion"
import { useStore } from "@/ContextTab/useStore"
import { formatUsername } from "@/utils/formatUsername"
import { trimHTML } from "@/utils/trimHTML"
import type { RefsStore } from "@/ContextApp/stores/references"

const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    dateMode?: DateMode
    showReadStatus?: boolean
  }>(),
  {
    isSelected: false,
    dateMode: DateMode.Regular,
    showReadStatus: false,
  }
)

const emit = defineEmits<{
  (e: "dblclick"): void
  (e: "dateModeChanged", value: DateMode): void
}>()

const slots = useSlots()

const refsStore = useStore<RefsStore>("references", {
  shared: true,
})

const { newsItemIsRead, newsItemIsScheduled, newsItemIsExpired } = useNewsItem(
  toRef(() => props.item.id)
)
const {
  version,
  getVersionAuthorsNames,
  isVersionOfTypeFlash,
  isVersionOfTypeExpress,
} = useNewsItemVersion(toRef(() => props.item.id))

const textHeader = computed(() => {
  if (
    props.item.version.headline
  ) {
    return trimHTML(props.item.version.headline).slice(0, 140)
  }
  return null
})

const textBody = computed(() => {
  if (props.item.version.body) {
    return trimHTML(props.item.version.body).slice(0, 120)
  }
  return null
})

const text = computed(() => {
  if (textHeader.value) {
    return textHeader.value
  } else if (textBody.value) {
    return textBody.value
  } else {
    return "Пустая новость"
  }
})

const embargoExpired = computed(() => {
  const ignoredStatusIds = [
    refsStore.data?.newsStatusesByName?.["PUBLISHED"].id,
    refsStore.data?.newsStatusesByName?.["DISPOSED"].id,
  ]

  return (
    !ignoredStatusIds.includes(version.value?.status_id) &&
    newsItemIsExpired.value
  )
})

const showEmbargoInfo = computed(() => {
  const ignoredStatusIds = [
    refsStore.data?.newsStatusesByName?.["PUBLISHED"].id,
    refsStore.data?.newsStatusesByName?.["DISPOSED"].id,
  ]
  return (
    !ignoredStatusIds.includes(version.value?.status_id) &&
    newsItemIsScheduled.value
  )
})

const authors = computed(() => {
  const statusIds = [
    refsStore.data?.newsStatusesByName?.["WAITS_FOR_PUBLICATION"].id,
    refsStore.data?.newsStatusesByName?.["PUBLISHED"].id,
  ]
  const authorsList = getVersionAuthorsNames.value
  let authorsFormatted = authorsList.join(", ")

  if (
    statusIds.includes(props.item.version.status_id) &&
    props.item.publisher
  ) {
    authorsFormatted =
      formatUsername(props.item.publisher.name) + " / " + authorsFormatted
  } else {
    authorsFormatted =
      (authorsList.length > 1 ? "Авторы: " : "Автор: ") + authorsFormatted
  }

  return authorsFormatted
})
</script>

<template>
  <div
    class="news-item"
    :class="{
      selected: isSelected,
      flash: isVersionOfTypeFlash,
      express: isVersionOfTypeExpress,
    }"
    @dblclick="emit('dblclick')"
  >
    <slot name="header">
      <div class="news-item__header">
        <span v-if="showReadStatus" class="news-item__status">
          <i v-show="!newsItemIsRead">●</i>
        </span>
        <PsNewsTypeButton :typeId="item.version.type_id" :disabled="true" />
        <span class="news-item__date">
          <DateWidget
            :date="item.status_modified_at"
            :mode="dateMode"
            clickable
            @dateModeChanged="$emit('dateModeChanged', $event)"
          />
        </span>
        <span v-show="embargoExpired" class="news-item__warning-icon">
          <PsTooltip content="Вышло время публикации" placement="top">
            <PsIcon name="warning" :size="18" />
          </PsTooltip>
        </span>
      </div>
    </slot>
    <slot name="body">
      <div class="news-item__text">
        <b v-if="textHeader">
          <slot name="before-text" :item="item" />{{ text }}
        </b>
        <span v-else-if="textBody">
          <slot name="before-text" :item="item" />{{ text }}
        </span>
        <span v-else class="-no-text">
          <slot name="before-text" :item="item" />{{ text }}
        </span>
      </div>
    </slot>
    <slot name="expansion">
      <span v-if="showEmbargoInfo" class="news-item__embargo">
        Будет опубликована&nbsp;
        <EmbargoBadge
          :date="version.publish_at"
          :postponed="version.is_postponed"
        />
      </span>
      <span class="news-item__authors">
        {{ authors }}
      </span>
      <slot name="expansion-extra" />
    </slot>
    <div v-if="slots.footer" class="news-item__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.news-item {
  padding: 0.5rem 1rem 3rem;
  border-bottom: 1px solid var(--color-gray-200);

  &.selected {
    --button--color: #bd985c;
    --button--hover--background: #f3e4ca;

    background-color: #fff6e6;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #f4b650;
    }
  }

  .news-item__header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0.4rem;
    color: var(--color-gray-300);
    font-size: 12px;
  }

  .news-item__warning-icon {
    margin-left: auto;
    color: var(--color-danger-tint-50);
  }

  .news-item__status {
    position: absolute;
    left: -0.7rem;
    font-size: 14px;
  }

  .news-item__date {
    margin-left: 2px;
  }

  .news-item__text {
    margin-bottom: 0.4rem;

    .-no-text {
      display: flex;
      font-style: italic;
      text-align: center;
      color: var(--color-gray-shade-150);
      height: 100%;
      align-items: center;
    }
  }

  .news-item__authors,
  .news-item__embargo {
    color: var(--color-gray-300);
    font-size: 12px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .news-item__footer {
    position: absolute;
    right: 1rem;
  }

  &.flash {
    & .news-item__text {
      color: var(--color-danger-shade-150);
      & span {
        &:not(.-no-text) {
          text-transform: uppercase;
        }
      }
    }
  }

  &.express {
    & .news-item__text {
      color: var(--color-danger-shade-150);
    }
  }
}
</style>
