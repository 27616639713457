<script setup lang="ts">
import { isThisYear, parseISO } from "date-fns"
import { computed } from "vue"
import formatToMsk, { type FormatOptions } from "@/utils/formatToMsk"

const props = withDefaults(
  defineProps<{
    date?: string | null
    postponed?: boolean
    notStyled?: boolean
  }>(),
  {
    date: null,
    postponed: false,
    notStyled: false,
  }
)

const dateParsed = computed(() => {
  return props.date ? parseISO(props.date) : null
})

const formattedDate = computed(() => {
  if (!dateParsed.value) {
    return ""
  }
  const formatOptions: FormatOptions = {
    year: undefined,
  }
  if (!isThisYear(dateParsed.value)) formatOptions.year = "numeric"
  return formatToMsk(dateParsed.value, formatOptions)
})

const formattedTitle = computed(() => {
  if (props.postponed) {
    return "Эмбарго до звонка"
  } else if (!dateParsed.value) {
    return ""
  }
  return "Будет опубликована " + formatToMsk(dateParsed.value)
})
</script>

<template>
  <span
    class="embargo-badge"
    :class="{ '-no-style': notStyled }"
    :title="formattedTitle"
  >
    <span class="embargo-badge__text">
      {{ postponed ? "Эмбарго до звонка" : formattedDate }}
    </span>
  </span>
</template>

<style lang="postcss" scoped>
.embargo-badge {
  display: inline-block;
  height: 1rem;
  margin: 0 2px;
  padding: 0 4px;
  line-height: 1rem;
  font-size: 12px;
  white-space: nowrap;
  background: var(--color-danger-50);
  color: var(--color-danger-shade-150);
  border-radius: 2px;

  &.-no-style {
    margin: 0;
    padding: 0;
    background: none;
    color: inherit;
  }
}
</style>
