import { onMounted, onBeforeUnmount } from "vue"

export default function useUpdateScheduler() {
  let unmounting = false

  const tasks: {
    select: (() => void) | null
    scroll: (() => void) | null
    keyboard: (() => void) | null
    views: Record<string, { index: number; handler: () => void }>
  } = {
    scroll: null,
    select: null,
    keyboard: null,
    views: {},
  }

  function updateTick(frameStart: number) {
    if (unmounting) return null

    const time = performance.now()
    if (time - frameStart > 15) {
      return requestAnimationFrame(updateTick)
    }
    if (typeof tasks.select === "function") {
      tasks.select()
      tasks.select = null
    }

    if (typeof tasks.scroll === "function") {
      tasks.scroll()
      tasks.scroll = null
    }

    if (performance.now() - time > 12) {
      return requestAnimationFrame(updateTick)
    }
    const viewTasks = Object.keys(tasks.views).sort(
      (vt1, vt2) => tasks.views[vt1].index - tasks.views[vt2].index
    )
    for (const uid of viewTasks) {
      if (tasks.views[uid]) {
        tasks.views[uid].handler()
        delete tasks.views[uid]
      }
    }

    if (typeof tasks.keyboard === "function") {
      tasks.keyboard()
      tasks.keyboard = null
    }

    return requestAnimationFrame(updateTick)
  }

  onMounted(() => {
    requestAnimationFrame(updateTick)
  })

  onBeforeUnmount(() => {
    unmounting = true
  })

  return { tasks }
}
