<script setup lang="ts">
import { ref, inject, onBeforeUnmount, watch } from "vue"
import Popover from "./Popover/Popover.vue"
import { KeyboardHandler } from "@/ContextTab/services/keyboardHandler"
import type { PopoverProps } from "./Popover/types"
import { KeyboardHandlerKey } from "@/injectionKeys"
import { v4 as uuid } from "uuid"

const props = withDefaults(defineProps<PopoverProps & { hideOnEscape?: boolean }>(), {
  contentStyle: undefined,
  arrow: true,
  hideOnClickAway: true,
  hideOnEscape: false,
})

const emit = defineEmits<{
  (e: "clickTrigger"): void,
  (e: "clickBody"): void,
  (e: "clickOutside"): void,
  (e: "show"): void,
  (e: "hide"): void,
}>()

const popover = ref<InstanceType<typeof Popover> | null>(null)

const keyboardHandler = inject(KeyboardHandlerKey) as KeyboardHandler

const popId = ref<string>(uuid())

function onShow() {
  emit('show')
}

function onHide() {
  keyboardHandler.unsubscribe(popId.value)
  emit('hide')
}

onBeforeUnmount(() => {
  keyboardHandler.unsubscribe(popId.value)
})

// onShow по факту не вызывается в нужный момент, поэтому watch
watch(() => props.open, (v) => {
  if (v) {
    if (
      props.trigger
      && props.trigger !== "hover"
      && props.hideOnEscape
    ) {
      keyboardHandler.subscribe({
        componentId: popId.value,
        eventType: 'keydown',
        callback: () => {
          popover.value?.hidePopover()
          return 'processed'
        },
        keys: ['Escape'],
      })
    }
  } else {
    keyboardHandler.unsubscribe(popId.value)
  }
})

</script>

<template>
  <Popover
    v-bind="props"
    ref="popover"
    class="ps-popover"
    @clickTrigger="emit('clickTrigger')"
    @clickBody="emit('clickBody')"
    @clickOutside="emit('clickOutside')"
    @show="onShow"
    @hide="onHide"
  >
    <slot />
    <template #body>
      <div class="ps-popover-body" v-bind="$attrs">
        <slot name="body" />
      </div>
    </template>
  </Popover>
</template>

<style lang="postcss">
.ps-popover {
  --popover--background: var(--color-white);
  --popover--color: var(--contrast-text-color-white);
  --popover--text-color: white;
  --popover--body--padding: var(--padding-1-2);
  --popover-border-width: var(--border-width);
  --popover-border-color: var(--border-color);
  --popover-border-style: solid;
  --popover--border-bottom-left-radius: var(--border-bottom-left-radius);
  --popover--border-bottom-right-radius: var(--border-bottom-right-radius);
  --popover--border-top-left-radius: var(--border-top-left-radius);
  --popover--border-top-right-radius: var(--border-top-right-radius);
  --popover--border-radius: var(--popover--border-top-left-radius)
    var(--popover--border-top-right-radius)
    var(--popover--border-bottom-right-radius)
    var(--popover--border-bottom-left-radius);
  --popover--box-shadow: var(--box-shadow);
  /* --popover--dark--color: var(--contrast-text-color-dark); */
  --popover--font-size: var(--font-size);
  /* --popover--lg--font-size: calc(var(--font-size) * var(--size-multiplier-lg)); */
  /* --popover--light--color: var(--contrast-text-color-white); */
  --popover--line-height: var(--line-height);
  --popover--max-width: 90vw;
  /* --popover--md--font-size: calc(var(--font-size) * var(--size-multiplier-md)); */
  /* --popover--sm--font-size: calc(var(--font-size) * var(--size-multiplier-sm)); */
  --popover--width: auto;
}
</style>
