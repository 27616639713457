import { useThrottleFn } from "@vueuse/core"
import { onMounted, onBeforeUnmount, type Ref } from "vue"

type Emit = (event: "selected", value: any) => void

export default function (
  props: any,
  emit: Emit,
  selectedIndex: Ref<number>,
  wrapper: Ref<any>,
  sizeMap: any,
  scrollToItem: (index: number) => void,
  wrapperSize: () => number,
  idField: string,
  tasks: any
) {
  const navKeys = () => [
    ...["ArrowUp", "ArrowDown"],
    ...(props.vimNavigation ? ["j", "k", "о", "л"] : []),
  ]
  const keysUp = () => [
    ...["ArrowUp"],
    ...(props.vimNavigation ? ["k", "л"] : []),
  ]
  const keysDown = () => [
    ...["ArrowDown"],
    ...(props.vimNavigation ? ["j", "о"] : []),
  ]

  function onKeyboardNav(event: KeyboardEvent) {
    if (!props.keyboardEnabled) return
    if (navKeys().includes(event.key)) {
      event.stopPropagation()
      event.preventDefault()

      tasks.keyboard = () => {
        // event.stopPropagation()
        // event.preventDefault()

        if (props.selectedId === null) return
        if (keysUp().includes(event.key)) {
          navUp()
        }

        if (keysDown().includes(event.key)) {
          navDown()
        }
      }
    }
  }

  function navUp() {
    if (selectedIndex.value === 0) {
      if (wrapper.value && wrapper.value.scrollTop > 0) {
        wrapper.value.scrollTop = 0
      }
      return
    }

    const newIndex = selectedIndex.value - 1

    const item = props.items[newIndex]
    if (!item) {
      return
    }
    const record = sizeMap[item[idField]]
    if (
      wrapper.value &&
      record &&
      record.getStart() < wrapper.value.scrollTop
    ) {
      scrollToItem(newIndex)
    }
    selectedIndex.value = newIndex
    emit("selected", item)
  }

  function navDown() {
    if (selectedIndex.value === props.items.length - 1) {
      if (
        wrapper.value &&
        wrapper.value.scrollTop !== wrapper.value.scrollHeight
      ) {
        wrapper.value.scrollTop = wrapper.value.scrollHeight
      }
      return
    }

    const newIndex = selectedIndex.value + 1

    const item = props.items[newIndex]
    if (!item) {
      return
    }
    const record = sizeMap[item[idField]]
    if (
      wrapper.value &&
      record &&
      record.getEnd() > wrapper.value.scrollTop + wrapperSize()
    ) {
      wrapper.value.scrollTop = record.getEnd() - wrapperSize()
    }
    selectedIndex.value = newIndex
    emit("selected", item)
  }

  const onNav =
    props.keyboardThrottle > 0
      ? useThrottleFn(onKeyboardNav, props.keyboardThrottle)
      : onKeyboardNav

  onMounted(() => {
    document.addEventListener("keydown", onNav)

    // document.addEventListener("keydown", event => {
    //   if (props.keyboardEnabled) {
    //     if (navKeys().includes(event.key)) {
    //       // event.stopPropagation()
    //       // event.preventDefault()
    //       onNav(event)
    //     }
    //   }
    // })
  })

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", onNav)
  })
}
