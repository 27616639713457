<script setup lang="ts">
import Panel from "@/ContextTab/components/Panel/Panel.vue"
import PsTag from "@/ContextTab/components/UI/PsTag.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import { useClipboard } from "@vueuse/core"
import { computed } from "vue"
import useGroupedCodes from "@/ContextTab/modules/news/composables/useGroupedCodes"

const props = defineProps<{
  item: any
}>()

const codes = computed(() => props.item.version?.codes?.map(x => x.code))
const { groupedCodes } = useGroupedCodes(codes)

const { copy } = useClipboard()
</script>

<template>
  <Panel
    class="item-codes-panel"
    background="#f2f2f2"
  >
    <template #content>
      <div v-if="groupedCodes" class="content">
        <div
          v-for="group in groupedCodes"
          :key="group.category.id"
          class="group"
        >
          <strong class="group__title">{{ group.category.name }}</strong>
          <PsTooltip
            v-for="code in group.codes"
            :key="code.id"
            :content="code.path"
          >
            <PsTag
              size="md"
              color="light"
              :hoverable="true"
              @click="() => copy(code.path)"
            >
              {{ code.name }}
            </PsTag>
          </PsTooltip>
        </div>
      </div>
    </template>
  </Panel>
</template>

<style scoped lang="postcss">
.item-codes-panel {
  width: 100%;
}
.content {
  overflow-y: auto;
  padding: 1.2em 0.65em;

  display: flex;
  flex-direction: column;
  gap: 1em;

  color: #444;

  & .group {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    align-items: flex-start;
  }

  & .group__title {
    /* сокращения пока убираем (blame -> Замир) */
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: inline-block; */

    color: hsl(0 0% 50%);
    font-size: 0.85em;
    margin-bottom: 0.2em;
  }
}
</style>
@/ContextTab/modules/news/composables/useGroupedCodes
