import type { SizeBreakpoints } from '@/ContextTab/composables/types'

export const SCREEN_BREAKPOINTS: SizeBreakpoints = Object.freeze({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1800,
})
