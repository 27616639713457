<script setup lang="ts">
import PresetsWidget from "./presets/PresetsWidget.vue"
import Configurator from "@/ContextTab/components/Configurator/Configurator.vue"
import PanelToolbar from "@/ContextTab/components/Toolbar/PanelToolbar.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import NewsAuthorsCodes from "@/ContextTab/modules/news/authors/NewsAuthorsCodes.vue"
import NewsFilterCodes from "@/ContextTab/modules/news/codes/components/NewsFilterCodes.vue"
import FiltersToolbar
  from "@/ContextTab/modules/news/published/components/toolbar/filters/FiltersToolbar.vue"
import PresetsToolbar
  from "@/ContextTab/modules/news/published/components/toolbar/presets/PresetsToolbar.vue"
import { deepEqual } from "fast-equals"
import { computed, reactive, toRaw, watch } from "vue"
import type { NewsPublishedPreset, NewsSetFilters } from "@/lib/types"
import type { CodeFilterState } from "@/ContextTab/modules/news/codes/types"
import { EQuadboxState } from "@/ContextTab/components/UI/types"
import type { SizeBreakpoints } from '@/ContextTab/composables/types'

type State = {
  localFilters: any
  configState: {
    users: boolean
    codes: boolean
    feeds: boolean
    presets: boolean
    [key: string]: boolean
  }
  colorIndicatorModalVisible: boolean
}
const props = defineProps<{
  filters: NewsSetFilters
  preset: NewsPublishedPreset | undefined
  panelMode: 1 | 2
  showSearch: boolean
}>()

const emit = defineEmits([
  "updateFilters",
  "cancel",
  "savePreset",
  // "createPreset",
  "renamePreset",
  "deletePreset",
  "applyPreset",
  "clear",
  "resetFilters",
  "clearPreset",
])

const state = reactive<State>({
  localFilters: JSON.parse(JSON.stringify(props.filters || {})),
  configState: {
    users: false,
    codes: false,
    feeds: false,
    presets: false,
  },
  colorIndicatorModalVisible: false,
})

watch(
  () => props.filters,
  next => {
    state.localFilters = JSON.parse(JSON.stringify(next))
  }
)

watch(
  () => props.showSearch,
  next => {
    if (next) {
      hide()
    }
  }
)

const codesFilters = computed(() => ({
  code_ids: props.filters?.code_ids,
  code_excluded_ids: props.filters?.code_excluded_ids,
}))

const feedCodesFilters = computed(() => ({
  feed_code_ids: props.filters?.feed_code_ids,
  feed_code_excluded_ids: props.filters?.feed_code_excluded_ids,
}))

const usersFilters = computed(() => ({
  user_ids: props.filters?.user_ids,
  user_excluded_ids: props.filters?.user_excluded_ids,
  user_group_ids: props.filters?.user_group_ids,
  user_group_excluded_ids: props.filters?.user_group_excluded_ids,
}))

const configurator = computed(
  () => Object.values(state.configState).indexOf(true) !== -1
)

const toolbarBreakpoints = computed<SizeBreakpoints>(() => {
  const res = {
    "xs": 0,
    "sm": 420,
    "md": 590,
    "lg": 650,
    "xl": 900,
    "xxl": 1300,
    "xxxl": 1800,
  }

  if (!props.preset) {
    Object.assign(res, {
      "md": 480,
      "lg": 560,
      "xl": 800,
      "xxxl": 1200,
    })
  }
  return res
})

const hide = () => {
  Object.keys(state.configState).forEach(key => {
    state.configState[key] = false
  })
  emit("cancel")
}

const configuratorSize = computed(() =>
  state.configState.presets ? "300px" : "450px"
)

const filtersEqualToPreset = computed(() => {
  const copy: Partial<NewsPublishedPreset> = { ...toRaw(props.preset) }
  delete copy.name
  delete copy.id
  return deepEqual(copy, toRaw(props.filters))
})

const filtersAreEmpty = computed(() => {
  const res = Object.values(props.filters).reduce((acc, cur) => {
    acc += cur.length
    return acc
  }, 0)
  return res <= 0
})

const toggle = (type: "codes" | "users" | "feeds" | "presets") => {
  Object.keys(state.configState)
    .filter(key => key !== type)
    .forEach((key: string) => {
      state.configState[key] = false
    })
  state.configState[type] = !state.configState[type]
}

const updateCodes = (type: "code" | "feed_code" = "code", filterState: CodeFilterState) => {
  const included: number[] = []
  const excluded: number[] = []
  Object.keys(filterState)
    .forEach((id) => {
      const state = filterState[id]?.state
      if (state === EQuadboxState.included) {
        included.push(parseInt(id))
      }
      if (state === EQuadboxState.excluded) {
        excluded.push(parseInt(id))
      }
    })
  state.localFilters[`${type}_ids`] = included
  state.localFilters[`${type}_excluded_ids`] = excluded
}

const updateUsers = (filterState: any) => {
  const initialState: any = {
    user_ids: [],
    user_excluded_ids: [],
    user_group_ids: [],
    user_group_excluded_ids: [],
  }
  Object.keys(filterState).forEach(id => {
    const state = filterState[id]?.state
    if (/G$/g.test(id)) {
      if (state === "included") {
        initialState.user_group_ids.push(parseInt(id))
      }
      if (state === "excluded") {
        initialState.user_group_excluded_ids.push(parseInt(id))
      }
    } else {
      if (state === "included") {
        initialState.user_ids.push(parseInt(id))
      }
      if (state === "excluded") {
        initialState.user_excluded_ids.push(parseInt(id))
      }
    }
  })

  Object.keys(initialState).forEach(key => {
    state.localFilters[key] = initialState[key]
  })
}

const clearCodes = () => {
  state.localFilters.code_ids = []
  state.localFilters.code_excluded_ids = []
  hide()
  emit("clear", "codes")
}

const clearFeeds = () => {
  state.localFilters.feed_code_ids = []
  state.localFilters.feed_code_excluded_ids = []
  hide()
  emit("clear", "feeds")
}

const clearUsers = () => {
  state.localFilters.user_ids = []
  state.localFilters.user_excluded_ids = []
  state.localFilters.user_group_ids = []
  state.localFilters.user_group_excluded_ids = []
  hide()
  emit("clear", "users")
}

const clearFilter = (type: string) => {
  switch (type) {
    case "codes":
      clearCodes()
      break
    case "feeds":
      clearFeeds()
      break
    case "users":
      clearUsers()
      break
  }
}

const savePreset = () => {
  emit("savePreset", JSON.parse(JSON.stringify(state.localFilters)))
}

const submit = () => {
  emit("updateFilters", JSON.parse(JSON.stringify(state.localFilters)))
  hide()
}

const applyPreset = (presetId: number | string) => {
  emit("applyPreset", presetId)
  hide()
}
</script>

<template>
  <PanelToolbar
    background="#1d4c83"
    class="published-toolbar"
    :breakpoints="toolbarBreakpoints"
  >
    <template #left>
      <FiltersToolbar
        :filters="filters"
        @clear="clearFilter"
        @toggle="toggle"
      />
    </template>
    <template #right>
      <PresetsToolbar
        :preset="preset"
        :configuratorOpen="configurator && state.configState.presets"
        :saveDisabled="filtersEqualToPreset"
        :filtersEmpty="filtersAreEmpty"
        :filters="state.localFilters"
        @toggle="toggle('presets')"
        @apply="applyPreset"
        @save="savePreset"
        @clear="emit('clearPreset')"
      />
    </template>
    <template v-if="configurator">
      <Configurator
        :useTeleport="true"
        :teleportTo="
          panelMode === 2
            ? '#popups-panel-horizontal'
            : '#popups-panel-vertical'
        "
        top="5.5rem"
        :height="configuratorSize"
        @submit="submit"
        @cancel="hide"
      >
        <NewsFilterCodes
          v-if="state.configState.codes"
          type="non-feeds"
          :filters="codesFilters"
          @update="updateCodes('code', $event)"
        />

        <NewsFilterCodes
          v-if="state.configState.feeds"
          type="feeds"
          :filters="feedCodesFilters"
          @update="updateCodes('feed_code', $event)"
        />

        <NewsAuthorsCodes
          v-if="state.configState.users"
          :filters="usersFilters"
          @update="updateUsers"
        />

        <template v-else-if="state.configState.presets">
          <PresetsWidget
            @apply="applyPreset"
            @resetFilters="emit('resetFilters')"
          />
        </template>
        <template v-if="state.configState.presets" #footer>
          <PsButton round @click="hide"> Закрыть </PsButton>
        </template>
      </Configurator>
    </template>
  </PanelToolbar>
</template>

<style scoped lang="postcss">
.published-toolbar {
  color: white;
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
  position: relative;
}
</style>
