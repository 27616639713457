<script setup lang="ts">
import CircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { computed } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { RefsStore } from "@/ContextApp/stores/references"

const props = withDefaults(
  defineProps<{
    typeId?: number
    alertsCount?: number
    loading?: boolean
  }>(),
  {
    typeId: 1,
    alertsCount: 0,
    loading: false,
  }
)

const emit = defineEmits<{
  (event: "click"): void
}>()

const refsStore = useStore<RefsStore>("references", {
  shared: true,
})

const iconName = computed(
  () =>
    "newsType/newstype-" +
      refsStore.data?.newsTypes?.[props.typeId].name.toLowerCase() || ""
)

const alertsCountStr = computed(() =>
  props.alertsCount > 99 ? "99+" : props.alertsCount
)
</script>

<template>
  <button
    class="tasks-panel-button"
    type="button"
    :class="{ highlighted: !!alertsCount }"
    @click="emit('click')"
  >
    <PsIcon :name="iconName" :size="18" />
    <CircularLoader v-if="loading" class="tasks-panel-button__loader" />
    <span v-else class="tasks-panel-button__alerts-count">{{
      alertsCountStr
    }}</span>
  </button>
</template>

<style scoped lang="postcss">
.tasks-panel-button {
  display: inline-flex;
  justify-content: space-between;
  align-items: self-end;
  white-space: nowrap;
  font-weight: 700;
  padding: 7px 5px 7px 4px;
  width: 42px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0.6;
  background-color: var(--color-white);
  transition: 0.3s filter ease;

  &.highlighted {
    background-color: #fff6e6;
    opacity: 1;
    color: var(--color-danger-shade-150);
  }

  &:hover {
    filter: brightness(90%);
  }
}
.tasks-panel-button__alerts-count {
  line-height: 1;
}
.tasks-panel-button__loader {
  --loader--sm--size: 16px;
}
</style>
