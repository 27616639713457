import { v1 as uuid } from "uuid"

export const createView = (item: any | null = null) => {
  return {
    item,
    uid: uuid(), // TODO: это не эффективно, тем более для бесконечного списка
  }
}

type SRecordPartial = {
   size: number,
  _start: number
  index: number
  updated: number
  getStart?: () => number | undefined
  getEnd?: () => number | undefined

}
export type SRecord = Required<SRecordPartial>

export const SizeRecord = (
  sizeMap: any,
  items: any[],
  size: number,
  index: number,
  idField: string,
  timestamp: number
): SRecord => {
  const sizeRecord: SRecordPartial = {
    size,
    _start: index === 0 ? 0 : sizeMap[items[index - 1][idField]].getEnd() + 1,
    index,
    updated: timestamp
  }

  sizeRecord.getStart = () => sizeRecord._start
  sizeRecord.getEnd = () => sizeRecord._start + sizeRecord.size
  return sizeRecord as SRecord
}
