import binarySearch from "./binarySearch"
import type { SRecord } from "./utils"

const OVERFLOW = 5

function firstVisible(
  items: Record<string, any>[],
  sizeMap: Record<string, SRecord>,
  idField: string,
  scrollerStart: number
) {
  const found = binarySearch(items, item => {
    const end = sizeMap[item[idField]]?.getEnd() ?? 0
    return 0 <= end + scrollerStart
  })
  return found === items.length ? 0 : found
}

function lastVisible(
  items: Record<string, any>[],
  sizeMap: Record<string, SRecord>,
  idField: string,
  scrollerStart: number,
  wrapperSize: number
) {
  const found = binarySearch(items, item => {
    const start = sizeMap[item[idField]]?.getStart() ?? 0
    return 0 < start + scrollerStart - wrapperSize
  })
  return found
}

export default function ({
  getItems,
  getSizeMap,
  getScrollerStart,
  getWrapperSize,
  idField,
}: {
  getItems: () => Record<string, any>[]
  getSizeMap: () => Record<string, any>
  getScrollerStart: () => number
  getWrapperSize: () => number
  idField: string
}) {
  function calculateVisibility() {
    const items = getItems()
    const sizeMap = getSizeMap()
    const scrollerStart = getScrollerStart()
    const wrapperSize = getWrapperSize()

    let first = 0
    let last = 0
    const firstFound = firstVisible(items, sizeMap, idField, scrollerStart)
    const lastFound = lastVisible(
      items.slice(firstFound + 1),
      sizeMap,
      idField,
      scrollerStart,
      wrapperSize
    )

    if (firstFound <= OVERFLOW) {
      first = 0
    } else {
      first = firstFound - OVERFLOW
    }
    if (items.length - lastFound <= OVERFLOW) {
      last = items.length
    } else {
      last = lastFound + OVERFLOW + firstFound + 1
    }
    return items.slice(first, last)
  }

  return {
    calculateVisibility,
  }
}
