<script setup lang="ts">
import AuthorsTreeItem from "./AuthorsTreeItem.vue"
import TreeSearch from "@/ContextTab/components/TreeSearch/TreeSearch.vue"
import { ref } from "vue"
import useTreeSearch from "@/ContextTab/components/TreeSearch/useTreeSearch"

withDefaults(
  defineProps<{
    items: any
    filterState: any
    mode?: "check" | "multi"
    placeholder?: string
  }>(),
  {
    mode: "check",
    placeholder: "Поиск по пользователям",
  }
)

const emit = defineEmits(["change"])

const tree = ref<InstanceType<typeof Tree> | null>(null)

const { makeSearchParams } = useTreeSearch()

const searchParams = makeSearchParams({
  fields: [
    "name",
    "asoi_name",
    "first_name",
    "last_name",
    "patronymic",
    "code",
  ],
})

const onClick = (event: MouseEvent) => {
  if (
    event?.target
    && (event.target instanceof Element)
    && event.target.closest(".tree-arrow")
  ) {
    return
  }
  event.stopPropagation()
}

const scrollToTop = () => {
  tree.value?.scrollToTop()
}
defineExpose({
  scrollToTop,
})
</script>

<template>
  <TreeSearch
    ref="tree"
    :searchParams="searchParams"
    :items="items"
    :initialSize="27"
    :showDepth="1"
    :padding="32"
    :buffer="300"
    idField="uid"
    sortField="code"
    emptyText="Ничего не найдено"
    :placeholder="placeholder"
  >
    <template #default="{ item, opened }">
      <AuthorsTreeItem
        :item="item"
        :filterState="filterState"
        :opened="opened || false"
        :mode="mode"
        @click="onClick"
        @change="emit('change', item, $event)"
      />
    </template>
  </TreeSearch>
</template>

<style scoped lang="postcss"></style>
