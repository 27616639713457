<script setup lang="ts">
import { ref, onMounted } from "vue"
import type { Ref } from "vue"

const props = withDefaults(
  defineProps<{
    clearable?: boolean
    autofocus?: boolean
    disabled?: boolean
    placeholder?: string
    throttle?: number
    initialQuery?: string
    enableKeyboard?: boolean
    // compact: boolean
    // dark: boolean
    // rounded: boolean
    // slim: boolean
  }>(),
  {
    clearable: true,
    autofocus: true,
    disabled: false,
    placeholder: "",
    throttle: 300,
    initialQuery: "",
    enableKeyboard: true,
  }
)

const emit = defineEmits(["input"])
const query = ref(props.initialQuery)
const focused = ref(false)
const input: Ref<null | HTMLElement> = ref(null)
let queryTimer: null | number = null

const focus = () => {
  if (input.value) {
    input.value.focus()
  }
}

// const blur = () => {
//   if (input.value) {
//     input.value.blur()
//   }
// }

const emitUpdate = () => {
  emit("input", query.value)
}

const clearQuery = () => {
  query.value = ""
  emit("input", query.value)
  focus()
}

const handleInput = () => {
  if (queryTimer) {
    clearTimeout(queryTimer)
  }
  queryTimer = setTimeout(emitUpdate, props.throttle)
}

onMounted(() => {
  if (props.autofocus) {
    focus()
  }
})
</script>

<template>
  <div class="pskit__search-field">
    <div class="pskit__search-field_wrapper">
      <section class="pskit__search-field_prepend-icon">
        <slot name="prepend-icon"></slot>
      </section>
      <input
        ref="input"
        v-model="query"
        type="search"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="handleInput"
        @focus="focused = true"
        @blur="focused = false"
      />
      <section
        class="pskit__search-field_append-icon clickable"
        @click="clearQuery"
      >
        <slot name="append-icon"></slot>
      </section>
    </div>
  </div>
</template>

<style>
.pskit__search-field {
  box-sizing: border-box;
  display: flex;
  padding: 5px 16px;
  width: 100%;
}
.pskit__search-field .clickable {
  cursor: pointer;
}

.pskit__search-field input {
  flex-grow: 1;
  display: block;
  height: 30px;
  width: 100%;
  padding: 0 5px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  caret-color: #1976d2;
  box-shadow: none;
}
.pskit__search-field::selection {
  color: #333;
  background: transparentize(#4d96ec, 0.5);
}

.pskit__search-field_wrapper {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  min-height: 30px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.pskit__search-field_prepend-icon,
.pskit__search-field_append-icon {
  display: flex;
  align-items: center;
  font-size: 0;
  opacity: 0.4;
}

.pskit__search-field_prepend-icon {
  transform: scaleX(-1);
}

.pskit__search-field_append-icon {
  margin-left: 5px;
}
</style>
