<script setup lang="ts">
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'

// it's a most left vertical bar (container only) with workspace & task panel buttons

const { isScreenSmall } = useScreenBreakpoints()
</script>

<template>
  <div
    class="ps-main-nav _background:dark-shade-150"
    :class="{sm: isScreenSmall}"
  >
    <div class="btn-group group-main">
      <slot name="group-main" />
    </div>
    <div class="btn-group group-secondary">
      <slot name="group-secondary" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.ps-main-nav {
  position: relative;
  color: var(--color-gray);

  --flex-dir: column;

  display: flex;
  justify-content: space-between;
  flex-direction: var(--flex-dir);
  z-index: 1;
  flex-grow: 1;

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: var(--flex-dir);
    flex-basis: max-content;
  }

  &.sm {
    --flex-dir: row;
  }
}
</style>
