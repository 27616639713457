<script setup lang="ts">
import Configurator from "@/ContextTab/components/Configurator/Configurator.vue"
import PsSubmitGroup from "@/ContextTab/components/PsSubmitGroup.vue"
import PsResponsiveButton from "@/ContextTab/components/UI/Button/PsResponsiveButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import NewsCodes from "@/ContextTab/modules/news/codes/components/NewsCodes.vue"
import { computed, reactive, ref, watch } from "vue"

const props = defineProps<{ item: any; newsLanguage: any; panelMode: 1 | 2 }>()
const emit = defineEmits(["republish", "extend", "submit"])
const codesPopup = ref(false)

const newsItemCodeIds = computed(
  () => props.item?.version?.codes?.map(({ code }) => code.id)
)

type CheckedState = { [key: string]: any }
const checkedState = reactive<CheckedState>({})

const onCheckedUpdate = (state: CheckedState) => {
  Object.assign(checkedState, state)
}

const onSubmit = () => {
  const ids = Object.keys(checkedState).filter(
    codeId => checkedState[codeId]?.state === "included"
  )
  codesPopup.value = false
  emit("submit", ids)
}

const resetState = () => {
  codesPopup.value = false
  Object.keys(checkedState).forEach((key: string) => {
    delete checkedState[key]
  })
}

watch(
  () => props.item,
  (next, prev) => {
    if (next?.id !== prev?.id) {
      resetState()
    }
  }
)
</script>

<template>
  <PsResponsiveButton
    size="sm"
    tooltip="Повтор"
    :showTooltip="['xs', 'sm', 'md']"
    @click="emit('republish', item.id)"
  >
    <template #prefix>
      <PbIcon name="publish" />
    </template>
    <template #lg>Повтор</template>
  </PsResponsiveButton>
  <PsResponsiveButton
    size="sm"
    tooltip="Расширить"
    :showTooltip="['xs', 'sm', 'md']"
    @click="emit('extend', item.id)"
  >
    <template #prefix>
      <PbIcon name="extension" />
    </template>
    <template #lg>Расширить</template>
  </PsResponsiveButton>
  <PsResponsiveButton
    size="sm"
    tooltip="Добавить коды"
    :showTooltip="['xs', 'sm']"
    @click="codesPopup = !codesPopup"
  >
    <template #prefix>
      <PbIcon name="plus" />
    </template>
    <template #md>Добавить коды</template>
  </PsResponsiveButton>

  <template v-if="codesPopup">
    <Configurator
      top="3.7rem"
      bottom="0"
      :height="panelMode === 2 ? '75%' : undefined"
      :useTeleport="panelMode === 2"
      teleportTo="#popups-preview-horizontal"
      @submit="() => null"
      @cancel="codesPopup = false"
    >
      <NewsCodes
        :omit="newsItemCodeIds"
        profileName="PUBLISH_NEWS"
        :lang="newsLanguage?.name"
        @update="onCheckedUpdate"
      />
      <template #footer>
        <PsSubmitGroup
          @submit="onSubmit"
          @cancel="codesPopup = false"
        />
      </template>
    </Configurator>
  </template>
</template>

<style scoped lang="postcss">
/* .toolbar {
  color: white;
  display: flex;
  gap: 0.5rem;
  flex-shrink: 1;
}
 */
.horizontal {
  box-sizing: border-box;
  max-height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 2.8rem;
}
</style>
