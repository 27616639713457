import Main from "./ContextTab/Main.vue"
import { Inkline } from "@inkline/inkline"
import { setupCalendar } from "v-calendar"
import { createApp } from "vue"
import messageHub from "@/ContextTab/services/messageHubClient"
import { tabMetrics } from "@/ContextTab/services/tabMetrics"
import { messageTypes } from "@/lib/messageHub/messages"
import { router } from "./ContextTab/router"
import "@/assets/styles/design-system/index.css"

import "@inkline/inkline/css/index.scss"
import "@inkline/inkline/css/utilities.scss"
// eslint-disable-next-line import/no-unresolved
import "virtual:svg-icons-register"
import "@/assets/styles/index.pcss"

let rendered = false

window.addEventListener("message", event => {
  if (event.data?.event === "PSExtReady") {
    window.extensionId = event.data.id
  }
})

if (globalThis.SharedWorker) {
  const appWorker = new SharedWorker(new URL("./ContextApp/index.ts", import.meta.url), {
    type: "module",
    name: "PsAppWorker",
  })
  // WARNING: постоянное общение с воркером, чтобы браузер точно по-тихому не убил воркер
  if (appWorker instanceof SharedWorker) {
    appWorker.port.start()
    setInterval(() => appWorker.port.postMessage("PING"), 1000)
  }

} else {
  new Worker(new URL("./ContextApp/index.ts", import.meta.url), {
    type: "module",
    name: "PsAppWorker",
  })
}

if (globalThis.SharedWorker) {
  const gwWorker = new SharedWorker(
    new URL("./ContextApp/services/gateway/index.ts", import.meta.url),
    {
      type: "module",
      name: "PsGatewayWorker",
    }
  )
  // WARNING: постоянное общение с воркером, чтобы браузер точно по-тихому не убил воркер
  if (gwWorker instanceof SharedWorker) {
    gwWorker.port.start()
    setInterval(() => gwWorker.port.postMessage("PING"), 1000)
  }
} else {
  new Worker(
    new URL("./ContextApp/services/gateway/index.ts", import.meta.url),
    {
      type: "module",
      name: "PsAppWorker",
    }
  )
}

messageHub.addMessageListener({
  metaFilter(meta) {
    return (
      messageTypes.byId(meta.typeId).name === messageTypes.names.service &&
      meta.signal === "init"
    )
  },
  callback(message) {
    if (["started", "ready"].includes(message.data.payload) && !rendered) {
      const app = createApp(Main)

      app.use(Inkline, {
        locale: "ru",
        size: "sm",
        colorMode: () => "colorful-light",
      })

      tabMetrics.init(app)

      app.use(setupCalendar, {})
      app.use(router)
      app.mount("#app")
      rendered = true
    }
  },
})

// проверяем, инициализировано ли уже приложение в AppWorker
messageHub.sendMessage(
  {
    typeId: messageTypes.byName("service").id,
    signal: "init",
  },
  null
)

messageHub.addMessageListener({
  metaFilter: meta =>
    messageTypes.byId(meta.typeId).name === messageTypes.names.service &&
    meta.signal === "reload",
  callback: () => globalThis.location.reload(),
})
