<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'

defineProps<{
  disabled?: boolean
}>();

const emit = defineEmits(["clear"])

const { isScreenExtraSmall } = useScreenBreakpoints()
</script>

<template>
  <PsFilterButton
    tooltip="Сбросить фильтры и пресет"
    :color="isScreenExtraSmall ? 'default' : null"
    showTooltip="always"
    :disabled="disabled"
    @click="emit('clear')"
  >
    <template #prefix>
      <PbIcon
        name="close"
        size="14"
      />
    </template>
    <template #xs>Сбросить</template>
    <template #sm></template>
    <template #xl>Сбросить</template>
  </PsFilterButton>
</template>
