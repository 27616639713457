<script setup lang="ts">
import TasksPanelListItem from "../components/TasksPanelListItem.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import NewsItemComment from "@/ContextTab/modules/news/tasks-panel/components/NewsItemComment.vue"
import { DateMode } from "@/ContextTab/components/Date/types"

withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    dateMode?: DateMode
    isLegacyRoute: boolean
  }>(),
  {
    isSelected: false,
    dateMode: DateMode.Regular,
  }
)
const emit = defineEmits<{
  (e: "open", id: number): void
}>()
</script>

<template>
  <TasksPanelListItem
    class="in-progress-list-item"
    :item="item"
    :isSelected="isSelected"
    :dateMode="dateMode"
    @dblclick="emit('open', item.id)"
  >
    <template #expansion-extra>
      <NewsItemComment
        v-if="item.first_comment"
        class="in-progress-list-item__comment"
        :comment="item.first_comment.body"
      />
    </template>
    <template #footer>
      <div v-if="isSelected" class="in-progress-list-item__footer">
        <PsTooltip
          :content="
            isLegacyRoute
              ? 'Открыть в отдельной панели'
              : 'Открыть в новой вкладке'
          "
          placement="top"
        >
          <PsButton
            circle
            :border="false"
            class="action-button"
            @click="emit('open', item.id)"
          >
            <PsIcon name="button-icon-show-more" :size="24" />
          </PsButton>
        </PsTooltip>
      </div>
    </template>
  </TasksPanelListItem>
</template>

<style lang="postcss" scoped>
.in-progress-list-item__footer .action-button:not(:disabled, .-disabled):hover {
  --button--color: #bd985c;
}
</style>
