<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
  item: any
  trackShift: number
  openPadding: number
  selected: boolean
  opened: boolean
}>()

defineSlots<{
  default(props: { item: any, selected: boolean, opened: boolean }): any,
}>()

const padding = computed(() => {
  return props.openPadding * (props.item.track.length - props.trackShift) + "px"
})
</script>

<template>
  <div :style="{ paddingLeft: padding }">
    <slot v-bind="{ item, selected, opened }" />
  </div>
</template>
