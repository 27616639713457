<script setup lang="ts">
import { ref, nextTick, onMounted, onBeforeUnmount, watch } from "vue"

const props = defineProps<{
  selected: boolean
  index: number
  initialSize: number
  start: number
  size: number
  view: { item: any },
  idField: string
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleResize: (data: any) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelect: (item: any) => void
}>()

defineSlots<{
  default(props: { item: any, selected: boolean, index: number }): any
}>()

const root = ref<HTMLElement | null>(null)
let observed = false

// TODO: очень НЕ эффективно создавать обзёрвер на каждый элемент списка, тем более бесконечного
const resizeObserver = new ResizeObserver(entries => {
  for (const entry of entries) {
    const size = Math.floor(entry.borderBoxSize[0].blockSize)

    if (size > 0 && size !== props.size) {
      props.handleResize({
        item: props.view.item,
        view: props.view,
        size,
        index: props.index
      })
    }
  }
})

/*
 * ##############################
 * Этот блок предотвращает появление зазоров между элементами списка.
 * По каким-то причинами при наивном подходе, используя только
 * ResizeObserver, расположение элемента списка может вычисляться некорректно.
 * Поэтому мы добавляем ещё один рендер для исправления ситуации.
 * По идее, благодаря планировщику, в один фрейм должен выполниться
 * либо код ниже, либо вызов функции из ResizeObserver, в зависимости
 * от того, что придёт позже
 * TODO: провести расследование вопроса.
 */
watch(
  () => props.view.item[props.idField],
  () => {
    nextTick().then(() => {
      if (root.value) {
        const size = Math.floor(root.value?.getBoundingClientRect().height)
        if (size > 0 && size !== props.size) {
          props.handleResize({
            item: props.view.item,
            view: props.view,
            size,
            index: props.index
          })
        }
      }
    })
  }
)
/*  ############################## */

function observe() {
  if (root.value) {
    resizeObserver.observe(root.value)
    observed = true
  }
}

onMounted(() => {
  observe()
  nextTick().then(() => {
    if (!observed) {
      observe()
    }
  })
})

onBeforeUnmount(() => {
  resizeObserver.disconnect()
})
</script>

<template>
  <div
    ref="root"
    class="pskit__virtual-list-item"
    :class="{
      'pskit__virtual-list-item_selected': props.selected,
    }"
    :style="{ transform: `translateY(${props.start}px)` }"
    @click="onSelect(props.view.item)"
  >
    <slot v-bind="{ item: view.item, selected, index }" />
  </div>
</template>

<style scoped lang="postcss">
.pskit__virtual-list-item {
  box-sizing: border-box;
  contain: inline-size layout paint style; /* inline-size is required and correct value */
  contain-intrinsic-height: auto v-bind(size);
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}
</style>
