import deepToRaw from "@/utils/deepToRaw"
/**
 * Функция сравнения, используемая по-умолчанию.
 * Предназначена для передачи во встроенный метод Array.sort()
 * Сортирует элементы по значению поля, переданного в sortField.
 * Умеет сравнивать значения типов String и Number.
 */
export function compareDefault(
  sortField: string,
  firstItem: any,
  secondItem: any
) {
  if (
    typeof firstItem[sortField] === "string" ||
    typeof firstItem[sortField] === "string"
  ) {
    if (
      secondItem[sortField].toString().toLowerCase() >
      firstItem[sortField].toString().toLowerCase()
    ) {
      return -1
    } else {
      return 1
    }
  } else {
    return firstItem[sortField] - secondItem[sortField]
  }
}

/**
 * Нормализует элементы для работы в PbVirtualTree.
 * Получает родителя переданного элемента и добавляет текущий в его поле children
 * Изменяет переданный массив элементов.
 *
 * @param      {Object}  items Объект, где ключ — id элемента, а значение — объект элемента
 */
export function normalizeChildrenByTrack(items: Record<string, any>) {
  for (const id in items) {
    let parsedId: string | number = id
    if (!isNaN(Number(id))) {
      parsedId = parseInt(id)
    }
    if (items[parsedId]?.track?.length > 1) {
      const parent =
        items[items[parsedId].track[items[parsedId].track.length - 1]]
      if (parent) {
        if (!parent.children) {
          parent.children = []
        }
        if (parent.children.includes(parsedId)) {
          parent.children.push(parsedId)
        }
      }
    }
  }
}
/**
 * Возвращает список, обработанный для использования в компоненте.
 * Рекурсивно обходит переданный объект и его дочерние элементы
 * и формирует поле track, в котором описа путь элмента в иерархии
 *
 * @return  { Обработынное дерево элементов с добавленным полем track }
 */
export function makeItems(
  item: Record<string, any>,
  childrenField: string = "children",
  idField: string = "id",
  items: Record<string, any> = {},
  parent: Record<string, any> | null = null
) {
  items[item[idField]] = item
  item.track = parent ? parent.track.concat(parent[idField]) : []
  if (item[childrenField]) {
    item[childrenField].forEach((childItem: any) => {
      makeItems(childItem, childrenField, idField, items, item)
    })
    item[childrenField] = item[childrenField].map(
      (child: Record<string, any>) => child[idField]
    )
  }
  return items
}

/**
 * Сортирует элементы, переставляя дочерние элементы сразу после родительских.
 *
 * @param      {Array}    itemsKeys                 Ключи списка (обычно списко id элментов)
 * @param      {Object}    itemsObj                 Объект с элементами, где ключи — id элементов
 * @param      {string | number}    sortField       Поле, по которому сортировать элементы
 * @param      {Function}  [compareFunction=null]   Функция сравнения
 */
export function sort(
  itemsKeys: string[],
  itemsObj: Record<string, any>,
  sortField: string,
  compareFunction?: ((...args: any[]) => number) | null
) {
  itemsKeys.sort((firstKey, secondKey) => {
    const firstItem = itemsObj[firstKey]
    const secondItem = itemsObj[secondKey]
    if (!firstItem || !secondItem) return 1
    if (firstItem.children?.length) {
      sort(secondItem.children, itemsObj, sortField, compareFunction)
    }
    if (firstItem[sortField] && secondItem[sortField]) {
      if (typeof compareFunction === "function") {
        return compareFunction(compareDefault, sortField, firstItem, secondItem)
      }
      return compareDefault(sortField, firstItem, secondItem)
    } else {
      return 1
    }
  })
}
/**
 * Добавление элемента в упорядоченный плоский список.
 * Элементы добавляются рекурсивно по принципу:
 * 'родитель 1','потомок 1.1 ', 'потомок 1.2', 'потомок 1.n', 'родитель 2', 'потомок 2.1', 'потомок 2.n'
 *
 * @param      {Object}          items   Объект с элементами списка вида {[id]:[элеменет]}
 * @param      {string}          key     Ключ текущего элемента
 * @param      {Array}  list    Список элементов
 */
export function addToList(
  items: Record<string, any>,
  key: string | number,
  usedIds: Record<string, boolean>,
  itemList: Record<string, any>[]
) {
  const item = items[key]
  if (!item) return

  // Если элемент уже есть в списке, его не надо добавлять
  if (usedIds[key] === true) return

  // Проверяем, что элемент не прямой потомок корневого элемента
  // Это значит, что у элемента есть родитель
  if (item.track.length > 1) {
    // Если родитель элемента ещё не добавлен в список, то не нужно добавлять текущий элемент:
    // он будет добавлен позже, при обходе детей его родителя
    if (!usedIds[item.track[item.track.length - 1]]) {
      return
    }
  }

  usedIds[key] = true

  itemList.push(item)

  // Рекурсивно обходим детей текущего элемента
  for (let i = 0; i < item.children.length; i++) {
    addToList(items, item.children[i], usedIds, itemList)
  }
}
/**
 * Создаёт список элментов, отсортированный по связям родительских с дочерними и полю sortField
 *
 * @param      {Object}           items      Объект с элементами, где ключи — id элементов
 * @param      {string | number}  sortField  Поле, по которому сортировать элементы
 * @param      {(sortField, firstItem, secondItem ) => number} compareFunction Функция сравнения
 * @return     {Array}  Отсортированный массив элементов
 */
export function makeList(
  items: Record<string, any>,
  sortField: string,
  compareFunction?: any
) {
  const itemsKeys = Object.keys(items)
  // Копируем объект и удаляем реактивность, чтобы избежать изменения содержимого переменной items
  const rawItems = deepToRaw(items)
  sort(itemsKeys, rawItems, sortField, compareFunction)
  const usedIds = {}
  const itemList: Record<string, any>[] = []
  for (let i = 0; i < itemsKeys.length; i++) {
    const id = itemsKeys[i]
    addToList(rawItems, id, usedIds, itemList)
  }

  return itemList
}

/**
 * Функция поиска по-умолчанию
 * Принимает список предикатов и проверяет каждый элемент списка.
 * Элемент считается прошедшим проверку, если все предикаты возвращают true
 *
 * @param      {Object}  argumants
 * @param      {Array}  argumants.list        Список
 * @param      {Object}  argumants.items      Объект всех элементов
 * @param      {Function[]}  [arg1.predicates=[]]  Список функций-предикатов
 * @return     {Array}   Отфилтрованный список
 */
export function defaultSearch({
  list,
  items,
  predicates = [],
  idField = "uid",
}: {
  list: any[]
  items: any
  predicates: any[]
  idField: string
}) {
  const filtered = []
  const usedItems: any = {}
  for (const item of list) {
    const check = predicates.reduce((acc, predicate) => {
      return acc && predicate(item)
    }, true)
    if (check) {
      if (item.track.length) {
        item.track.forEach((id: string | number, index: number) => {
          // Первый элемент всегда корневой, его не нужно показывать при поиске
          if (index === 0) return
          const itemFromTrack = items[id]
          if (itemFromTrack) {
            if (!usedItems[id]) {
              usedItems[id] = true
              filtered.push(itemFromTrack)
            }
          }
        })
      }
      usedItems[item[idField]] = true
      filtered.push(item)
    }
  }
  return filtered
}

export type TreeSearchParams = {
  enabled: boolean
  autofocus: boolean
  minimalQueryLength: number
  caseSensitive: boolean
  initialQuery: string
  fields: string[]
  throttle: number
  searchFunction: typeof defaultSearch
  predicates: any[]
}

const SearchParamsDefault = (): TreeSearchParams => ({
  enabled: true,
  autofocus: true,
  minimalQueryLength: 1,
  caseSensitive: false,
  initialQuery: "",
  fields: ["name", "label"],
  throttle: 100,
  searchFunction: defaultSearch,
  predicates: [],
})

export type DefaultSearchParams = ReturnType<typeof SearchParamsDefault>

export const makeSearchParams = (
  params: Partial<DefaultSearchParams> = {}
): DefaultSearchParams => ({
  ...SearchParamsDefault(),
  ...params,
})

/**
 * * Создаёт функцию-предикат, используемую для поиска.
 * По умолчанию предикат принимает item и проверяет вхождение значений
 * полей options.params.fields в переданный searchString
 *
 * @param      {Object}  options
 * @param      {string}  options.searchString          Строка для поиска
 * @param      {<type>}  [arg1.params={field:'name'}]  Параметры поиска
 * @return     {Boolean}  true если элемент проходит проверку, false — если нет
 */
export function makeDefaultPredicate({
  searchString,
  params = { fields: ["name"] },
}: {
  searchString: string
  params: Partial<ReturnType<typeof SearchParamsDefault>>
}) {
  return (item: any) =>
    params.fields &&
    params.fields.reduce((acc: boolean, field: string) => {
      let fieldValue = item[field]
      if (!params.caseSensitive) {
        fieldValue = fieldValue ? fieldValue.toLowerCase() : null
        searchString = searchString.toLowerCase()
      }
      return acc || !!(fieldValue && fieldValue.includes(searchString))
    }, false)
}
