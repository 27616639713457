export default function binarySearch(
  array: any[],
  pred: (item: Record<string, any>) => boolean
) {
  let lo = -1,
    hi = array.length
  while (1 + lo < hi) {
    const mi = lo + ((hi - lo) >> 1)
    if (pred(array[mi])) {
      hi = mi
    } else {
      lo = mi
    }
  }
  return hi
}
